<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav">
    <vue-headful
      title="Template Proposals | Octoa"
    />

    <v-video-modal
      name="overview"
      title="Proposal template"
      description="A video to help you get started."
      step="proposal-template"
      thumbnail="templates.png"
      embed="EvsUFO3fICY"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <v-dialog />
    <div class="flex flex-wrap mb-12">
      <div class="w-full lg:w-1/4">
        <template-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div
          class="lg:mt-0 bg-white md:h-full mx-4 my-4 px-4 py-8 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded"
          :class="{ 'mt-20' : list && list.length > 0 }"
        >
          <div
            v-if="loading"
            class="text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="50"
              class=" py-10"
            >
          </div>

          <div
            v-if="!loading && list && list.length == 0"
            class="text-center"
          >
            <v-notification
              content="Create your first proposal. Send us an email and we gladly help you out!"
              :hide-close="true"
            />

            <div class="flex flex-wrap items-center justify-center">
              <div class="mx-2">
                <v-tutorial-button @click="showTutorialVideoModal" />
              </div>
              <div class="mx-2">
                <router-link
                  to="/templates/proposals/create"
                  class="green-btn my-5 inline-block"
                >
                  Create New Proposal Template
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="!loading && list && list.length > 0">
            <div class="template_list__action flex items-center justify-end">
              <div class="mr-2">
                <v-tutorial-button @click="showTutorialVideoModal" />
              </div>
              <div class="ml-2">
                <router-link
                  to="/templates/proposals/create"
                  class="green-btn "
                >
                  New Proposal
                </router-link>
              </div>
            </div>
            <v-client-table
              ref="datatable"
              :data="list"
              :columns="columns"
              :options="options"
              @row-click="goDetail"
              @pagination="onPagination"
            >
              <div
                slot="created_at"
                slot-scope="props"
              >
                {{ props.row.created_at | humanDateNoConvert }}
              </div>

              <div
                slot="cta"
                slot-scope="props"
              >
                <img
                  v-tooltip="{ ...tooltip, content: 'Duplicate' }"
                  class="absolute pin-r pin-t z-10 mr-10"
                  src="@/assets/img/icons/ico-duplicate-grey.svg"
                  alt="Delete"
                  width="15"
                  @click="duplicate($event, props.row.id)"
                >
                <img
                  v-tooltip="{ ...tooltip, content: 'Delete' }"
                  class="absolute pin-r pin-t z-10 mr-2"
                  src="@/assets/img/icons/delete.svg"
                  alt="Delete"
                  width="17"
                  @click="deleteItem($event, props.row.id)"
                >
              </div>
            </v-client-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  data(){
      return {
        page: 1,
        list: null,
        loading: false,
        timezone: '',
        user: auth.user(),
        isShowTutorialVideoModal: false,
        tooltip: {
          delay: 0,
          show: false,
          offset: 5,
          trigger: 'hover',
          classes: ['header-tooltip'],
          placement: 'bottom'
        },
        columns: ['name', 'created_at', 'cta'],
        options: {
          filterable: false,
          headings: {
            name: 'Name',
            created_at: 'Date',
            cta: '',
          },
          columnsDisplay: {
            created_at: 'min_tabletL',
            subject: 'min_tabletL',
          },
          orderBy: { column:'name', ascending: true },
          sortable: ['name', 'created_at'],
          descOrderColumns: ['date'],
          perPage: 20,
          perPageValues: [20,50,75,100],
        }
      }
  },
  async mounted(){
    this.timezone = this.user.companySettings.timezone

    if(this.$route.query.get == 'sample'){
      try {
        const { data } = await this.$api.get('templateProposal').sample(this.user.company.id)
      } catch(e){
        logException(e)
      }
    }

    await this.getList()
  },
  methods: {
    onPagination(index){
      this.page = index
    },
    goDetail(data){
      this.$router.push('/templates/proposals/'+data.row.id)
    },
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    async getList(){
      this.loading = true
      try {
        const { data } = await this.$api.get('templateProposal').list(this.user.company.id)
        this.list = data.proposals
        this.loading = false
      } catch(e){
        logException(e)
      }
    },
    async duplicate(e,id){
      e.stopPropagation()

      const { data } = await this.$api.get('templateProposal').duplicate(this.user.company.id, id)
      await this.getList()
      this.$refs.datatable.setPage(this.page)
    },
    async deleteItem(e, id){
      e.stopPropagation()

      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'If you delete this, you will lose it\'s content.',
      })
      if (canDelete) {
        try {

          const { data } = await this.$api.get('templateProposal').delete(id)
          await this.getList()
          this.$refs.datatable.setPage(this.page)

        } catch(e){
          logException(e)
        }
      }
    },
  },
}
</script>
